document.addEventListener("DOMContentLoaded", () => {

   (() => {
       'use strict'
   
       const reviews = new Swiper('#reviews__slider', {
           effect: 'coverflow',
           centeredSlides: true,
           slidesPerView: 'auto',
           coverflowEffect: {
               rotate: 30,
               stretch: 0,
               depth: 100,
               modifier: 1,
               slideShadows: true,
           },
           breakpoints: {
               320: {
                   spaceBetween: 10,
               },
               1440: {
                   spaceBetween: 30
               }
           },
           pagination: {
             el: '.swiper-pagination',
           },
           navigation: {
               nextEl: '.swiper-button-next',
               prevEl: '.swiper-button-prev',
           },
           on: {
               init: initCounter()
           },
       });
   
       const certificates = new Swiper('#certificates__slider', {
           slidesPerView: 'auto',
           spaceBetween: 16,
           // centeredSlides: true,
           breakpoints: {
               1440: {
                   spaceBetween: 32,
               },
           },
           navigation: {
               nextEl: '.swiper-button-next',
               prevEl: '.swiper-button-prev',
           },
       });
   
       reviews.on('slideChange', setCounter);
   
       function setCounter() {
           const activeSlideIndex = this.realIndex + 1
           const reCounter = document.querySelector('#reviewsCounter span')
           
           reCounter.innerText = '0' + activeSlideIndex
       }
       function initCounter() {
           const allSlides = document.querySelector('.allSlides')
           const slidesCount = document.querySelectorAll('.review__wraper').length
           
           allSlides.innerText = '0' + slidesCount
       }
         
       
   })();

   document.addEventListener('touchend', blurBtn)
   document.addEventListener('click', blurBtn)

   function blurBtn(e) {
      if (e.target.closest('.navBtn')) {
         setTimeout(() => {
            e.target.closest('.navBtn').blur();
         }, 200);
      }
   }

   document.querySelectorAll('.certificate__link').forEach(link => {
      link.addEventListener('click', e => {
         e.preventDefault()
         document.body.classList.add('modal_open')

         let id = link.getAttribute('href')
         document.querySelector(id).classList.add('open')

         document.querySelectorAll('.close_modal').forEach(closeBtn => {
            closeBtn.addEventListener('click', e => {
               e.preventDefault()
               document.body.classList.remove('modal_open')
               document.querySelector(id).classList.remove('open')
            })
         })
      })
   })

   const svgAnimation = (el) => {
      let totalLen = 0
      const speed = 2
      let delay = 2
      
      const paths = document.querySelectorAll(el)

         paths.forEach(element => {
            let elLen = element.getTotalLength()
            totalLen += elLen
         })

         paths.forEach(element => {
            const elLen = element.getTotalLength()
            let duration = elLen / totalLen * speed

            element.style.animationDuration = `${duration}s`
            element.style.animationDelay = `${delay}s`
            
            element.setAttribute('stroke-dasharray', elLen)
            element.setAttribute('stroke-dashoffset', elLen)
            
            delay += duration + 0.2
         })
         
   }
   svgAnimation('.line')


   

   const review_1 = new Plyr('#review_1');
   const review_2 = new Plyr('#review_2');
   const review_3 = new Plyr('#review_3');
   const review_4 = new Plyr('#review_4');
   const review_5 = new Plyr('#review_5');

   //замена ствндартной svg на кастомную
   document.querySelectorAll('button.plyr__control--overlaid use').forEach(el => {
      el.setAttribute('xlink:href', '#play')
   })

      
});